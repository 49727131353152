
import router, { ROUTES } from "@/router";
import { useStore } from "@/store";
import { AppActions, AppMutation } from "@/store/types";
import {
  BaseUrlParams,
  CardInfoParams,
  LogoutParams,
  SingleDeskParams,
} from "@/types/api-params";
import BussEventType from "@/types/BussEventType";
import { Desk } from "@/types/Desk";
import { getValueByKey } from "@/utils";
import {
  computed,
  CurrentDesk,
  defineComponent,
  Emitter,
  inject,
  LimitInfo,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import RoadMap from "@/components/roadmap/index.vue";
import DTBetGroup from "@/components/betting-view/dt-bet-group.vue";
import BBetGroup from "@/components/betting-view/b-bet-group.vue";
import PedictionBtn from "@/components/prediction-btn.vue";
import { VTextMarquee } from "vue-text-marquee";
import CryptoJS from "crypto-js";
import {
  GAMETYPE,
  RoadMapSummaryData,
} from "@/components/roadmap/datamodel/types";
import { RoadMapData } from "@/components/roadmap/datamodel/RoadMapData";
import { Dictionary } from "@/types/dictionary";
import { GameResult } from "@/types/GameResult";
import { GameRoundState, GAME_TYPE, ACT } from "@/types/types";
import { MessageBoxEvent, MessageBoxActionType } from "@/types/MessageBox";
import BtnDefault from "@/components/btn-default.vue";
import SwitchDesk from "@/components/switch-table.vue";
import CustomChip from "@/components/custom-chip.vue";
import audioPlayer, { SOUNDKEY } from "@/utils/sounds";
import { useI18n } from "vue-i18n";
import { AxiosSource, fetch } from "@/api";
import axios from "axios";
import dcardResult from "@/components/card-result.vue";
import mCardResult from "@/components/m-card-result.vue";

export default defineComponent({
  name: "desk",
  components: {
    "road-map": RoadMap,
    "marquee-text": VTextMarquee,
    "dt-bet-group": DTBetGroup,
    "b-bet-group": BBetGroup,
    "pre-btn": PedictionBtn,
    "btn-default": BtnDefault,
    "switch-desk": SwitchDesk,
    "custom-chip": CustomChip,
    "card-result": dcardResult,
    "m-card-result": mCardResult,
  },
  props: {
    desk: {
      default: -1,
      type: Number as PropType<number>,
    },
    gameType: {
      default: -1,
      type: Number as PropType<number>,
    },
    xian: {
      default: -1,
      type: Number as PropType<number>,
    },
  },
  setup(props) {
    const emitter = inject("emitter") as Emitter;
    const store = useStore();
    const { t } = useI18n();
    const roadmapview = ref<typeof RoadMap | undefined>(undefined);
    const online = ref<boolean>(navigator.onLine);
    const showSwitchTable = ref<boolean>(false);
    const activeChip = ref(0);

    let videoPlayerCollection: any[] = [];

    const deskData = ref<Desk | undefined>(undefined);
    const betView = ref<undefined>();
    const videoIsLoading = ref<boolean>(true);
    const roadMapKey = ref<number>(new Date().getTime());
    const currentCount = ref<number>(-1);
    const allowBet = ref<boolean>(false);
    const betViewKey = ref<number>(new Date().getTime());

    const winOrLostAmount = ref<number>(0);
    const tempTotalBet = ref<number>(0);
    const is360Video = ref<boolean>(false);
    const showMobileMenu = ref<boolean>(false);
    const showCardDisplay = ref(false);

    let videos: string[] = [];

    let needReloadUI = true;
    let countDownInterval: number | boolean = false;
    // let deskResult = ''
    let currentGameId: string | undefined = undefined;
    let needRefreshGameResult = true;
    let currentGameResult: GameResult | undefined = undefined;

    let showWinnerInterval: number | boolean = false;

    const nextRedRoadMapSummaryData = ref<RoadMapSummaryData>({});
    const nextBlueRoadMapSummaryData = ref<RoadMapSummaryData>({});

    const hasUnconfimerBet = ref<boolean>(false);

    let serviceTimeOut: number | boolean = false;
    let failureCounter = 0;

    const PULL_INTERVAL = 2000;
    const PULL_CARD_INTERVAL = 1500;

    let cardRequestTimeout: number | boolean = false;

    let nodePlayer!: any;

    const stopRequestingCard = () => {
      if (typeof cardRequestTimeout === "number") {
        clearTimeout(cardRequestTimeout);
        cardRequestTimeout = false;
      }
    };

    const loadData = (forceReload = false) => {
      if (!currentDesk.value) {
        router.push({ name: "loby" });
        return;
      }

      const params = new SingleDeskParams(
        currentDesk.value.desk,
        user.value,
        token.value,
        lang.value
      );

      if (!forceReload) {
        store.commit(AppMutation.SHOW_LOADING);
      }

      store
        .dispatch(AppActions.GET_DESK, params)
        .then((response: string) => {
          failureCounter = 0;
          if (!forceReload) store.commit(AppMutation.HIDE_LOADING);
          const err = getValueByKey(response, "err");
          if (err) {
            OnSessionExpired();
          } else {
            const _deskValue = Desk.Parse(response);

            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has("desk")) {
              const _desk = parseInt(urlParams.get("desk") || "");

              if (_desk === params.desk) {
                deskData.value = _deskValue;
                if (notice.value !== deskData.value.notice) {
                  store.commit(AppMutation.SET_NOTICE, deskData.value.notice);
                }

                store.commit(AppMutation.SET_COIN, deskData.value.balance);

                if (deskData.value.currentTime < 0 && _deskValue.status !== 0) {
                  cardRequestTimeout = setTimeout(() => {
                    OnrecievedDeskResult();
                  }, PULL_CARD_INTERVAL);
                  // Request CARD
                } else {
                  serviceTimeOut = setTimeout(() => {
                    loadData(true);
                  }, PULL_INTERVAL);
                }

                if (videoIsLoading.value) {
                  getVideoInfos();
                }
              }
            }
          }
        })
        .catch((e) => {
          failureCounter += 1;

          if (!forceReload) {
            store.commit(AppMutation.HIDE_LOADING);
            emitter.emit(BussEventType.TOAST_MESSAGE, e);
          }

          if (failureCounter > 3) {
            stopService();
            emitter.emit(BussEventType.TOAST_MESSAGE, e);
            store.commit(AppMutation.CLEAR_ALL);
            router.push({ name: ROUTES.LOGIN });
            return;
          }

          serviceTimeOut = setTimeout(() => {
            loadData(true);
          }, PULL_INTERVAL);
        });
    };

    const getVideoInfos = () => {
      const _params = {
        ...new BaseUrlParams(ACT.VIDEO_INFOS),
        sessionID: token.value,
        desk: props.desk,
        username: user.value,
      };

      fetch(_params)
        .then((response) => {
          if (response.data !== "") {
            videos = response.data.split("#");
            initPlayer();
          }
        })
        .catch(() => {
          //
        });
    };

    const stopService = () => {
      if (AxiosSource) {
        // Cancel All Api Pending Request
        AxiosSource.Source.cancel();
        // Create a new Cancel Token for Canceling pending request
        AxiosSource.Source = axios.CancelToken.source();
      }

      if (typeof serviceTimeOut === "number") {
        clearTimeout(serviceTimeOut);
        serviceTimeOut = false;
      }

      stopRequestingCard();
    };

    const handleWindowResized = () => {
      roadMapKey.value = new Date().getTime();
    };

    const formatCash = (n: number): string => {
      if (n < 1e3) return n.toString();
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";

      return n.toString();
    };

    const toggleFullScreen = () => {
      emitter.emit(BussEventType.TOGLE_FULL_SCREEN);
    };

    const initPlayer = () => {
      videoIsLoading.value = true;
      disposePlayer();
      if (!videoIsOn.value) return;

      // const videoPaths = [
      //   process.env.VIDEO1_PATH,
      //   process.env.VIDEO2_PATH,
      //   process.env.VIDEO3_PATH,
      // ];
      let isVideoConnected = false;

      videos.forEach((vPath) => {
        // let videoname = "bb" + currentDesk.value.desk;

        // if (videoname === "bb29") videoname = "bb12";
        // else if (videoname === "bb30") videoname = "bb13";

        // const timeStamp = new Date(
        //   new Date().getTime() + 0.4 * 60000
        // ).getTime();

        // if (is360Video.value) videoname = "360video";

        // const hashMD5 = CryptoJS.MD5(
        //   `/${videoname}/kkvideocg88-${timeStamp}-${process.env.VIDEO_KEY}`
        // );

        // const videoUrl = `${vPath}/${videoname}/kkvideocg88.flv?sign=${timeStamp}-${hashMD5.toString()}`;
        // eslint-disable-next-line no-undef
        let _player: NodePlayer | any = new NodePlayer();

        _player.setVolume(0.0);
        _player.setScaleMode(2);
        _player.setView("videoplayer2");
        _player.setBufferTime(1000);
        _player.on("start", () => {
          if (!isVideoConnected) {
            isVideoConnected = true;
            nodePlayer = _player;
            nodePlayer.clearView();
            nodePlayer.setView("videoplayer1");
            nodePlayer.on("buffer", (evt: "empty" | "buffering" | "full") => {
              if (evt === "empty" || evt === "buffering") {
                videoIsLoading.value = true;
              } else if (evt === "full") {
                videoIsLoading.value = false;
              }
            });
            nodePlayer.on("error", (e: any) => {
              videoIsLoading.value = true;
              setTimeout(() => {
                nodePlayer.clearView();
                nodePlayer.stop();
                initPlayer();
              }, 1000);
            });
          } else {
            _player.clearView();
            _player.stop();
          }
        });
        _player.on("error", () => {
          //
        });
        videoPlayerCollection.push(_player);
        // _player.start(videoUrl);
        _player.start(vPath);
      });
    };

    const disposePlayer = () => {
      if (nodePlayer) {
        nodePlayer.stop();
        nodePlayer.clearView();
        nodePlayer = undefined;
        videoIsLoading.value = true;
      }

      videoPlayerCollection.forEach((_player) => {
        if (_player) {
          _player.clearView();
          _player.stop();
        }
      });
      videoPlayerCollection = [];
    };

    const reloadUI = () => {
      // deskResult = _deskResult
      tempTotalBet.value = 0;

      // console.log('reset UI')
      // reset betting
    };

    const startCountDown = () => {
      if (typeof countDownInterval !== "number") {
        allowBet.value = true;
        tempTotalBet.value = 0;
        currentCount.value = deskData.value?.currentTime ?? 0;
        if (currentCount.value > 20) {
          audioPlayer.Play(SOUNDKEY.START_BET);
        }

        countDownInterval = setInterval(() => {
          if (currentCount.value < 0) {
            stopCountDown();
            audioPlayer.Play(SOUNDKEY.STOP_BET);
          } else {
            if (currentCount.value <= 11 && currentCount.value > 0) {
              audioPlayer.Play(SOUNDKEY.TICK);
            }
            currentCount.value -= 1;
          }
        }, 1000);
      }
    };
    const onShowLanguageMsbBox = () => {
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.open,
        name: "lang",
      };

      emitter.emit(BussEventType.DOALOG, _event);
    };

    const stopCountDown = () => {
      if (typeof countDownInterval === "number") {
        clearInterval(countDownInterval);
        countDownInterval = false;
        currentCount.value = -1;
        allowBet.value = false;
        if (typeof betView.value !== "undefined") {
          const view = betView.value as any;
          if (view) view.resetBet();
        }
      }
    };
    const stopShowWinner = () => {
      if (typeof showWinnerInterval === "number") {
        clearInterval(showWinnerInterval);
        showWinnerInterval = false;
        winOrLostAmount.value = 0;
      }
    };

    //#region Click Helpers

    const chipClicked = (_index: number, amount: number) => {
      if (_index !== activeChip.value) activeChip.value = _index;
    };

    const predict = (preType: number) => {
      roadmapview.value?.Predict(preType);
    };

    const toggleVideo = () => {
      let _val = 1;
      if (videoIsOn.value) _val = 0;
      else _val = 1;

      store.commit(AppMutation.SET_VIDEO_STATE, _val);

      if (_val !== 1) disposePlayer();
      else initPlayer();
    };

    const toggleSound = () => {
      let _val = 1;
      if (audioIsOn.value) _val = 0;
      else _val = 1;

      store.commit(AppMutation.SET_SOUND_STATE, _val);
      if (audioPlayer) audioPlayer.isOn = _val;
    };

    //#endregion

    const togleVideoView = () => {
      videoIsLoading.value = true;
      is360Video.value = !is360Video.value;
      initPlayer();
    };

    const onSelectTable = (
      deskNo: number,
      gType: number,
      apiType = 4,
      xian = -1
    ) => {
      if (props.desk === deskNo) {
        if (props.xian !== xian) {
          const _currentDesk: CurrentDesk = {
            desk: deskNo,
            gameType: apiType,
            xian: xian,
          };

          router.replace({
            query: _currentDesk,
          });

          store.commit(AppMutation.SET_GAME_OPTION, apiType);
          setCurrentDesk(_currentDesk);
        }
        return;
      }

      const _currentDesk: CurrentDesk = {
        desk: deskNo,
        gameType: apiType,
        xian: xian !== -1 ? xian : props.xian,
      };

      router.replace({
        query: _currentDesk,
      });

      store.commit(AppMutation.SET_GAME_OPTION, apiType);

      // stop prediction if posible
      stopService();
      stopRequestingCard();
      stopCountDown();
      setCurrentDesk(_currentDesk);
      needReloadUI = true;
      reloadUI();

      // initPlayer();
      videos = [];
      videoIsLoading.value = true;
      betViewKey.value = new Date().getTime();
      currentGameId = undefined;
      loadData();
    };

    const setCurrentDesk = (_currentDesk: CurrentDesk) => {
      store.commit(AppMutation.SET_CURRENT_DESK, _currentDesk);
    };

    const isInthisTable = (deskId: number) => {
      if (!currentDesk.value) return props.desk === deskId;

      return currentDesk.value.desk === deskId;
    };

    const handleTempBetUpdated = (isEnable: boolean) => {
      hasUnconfimerBet.value = isEnable;
    };

    const onRoadMapDataParsed = (
      a: RoadMapData,
      b: RoadMapData,
      c: RoadMapData
    ) => {
      nextRedRoadMapSummaryData.value = { data: b };
      nextBlueRoadMapSummaryData.value = { data: c };
    };

    const OnrecievedDeskResult = () => {
      if (
        currentGameId === undefined ||
        resultCache.value.ContainsKey(currentGameId)
      ) {
        if (currentGameId !== deskData.value?.gameId) {
          currentGameId = deskData.value?.gameId;
        }
      }

      if ((deskData.value?.currentTime as number) <= 0) {
        needRefreshGameResult = true;
      }

      if (
        needRefreshGameResult &&
        currentGameId !== undefined &&
        !resultCache.value.ContainsKey(currentGameId)
      ) {
        // get card result
        const model = new CardInfoParams(user.value, currentGameId);

        store
          .dispatch(AppActions.GET_CARD_INFO, model)
          .then((response: string) => {
            if (response.includes("err=2")) {
              stopService();
              store.commit(AppMutation.CLEAR_ALL);
              emitter.emit(BussEventType.TOAST_MESSAGE, "会话已过期");
              router.push({ name: "select-server-line" });
            } else {
              if (currentGameResult !== undefined) {
                if (currentGameResult.rawData === response) {
                  cardRequestTimeout = setTimeout(() => {
                    OnrecievedDeskResult();
                  }, PULL_CARD_INTERVAL);
                  return;
                }
              }

              if (
                currentGameId !== undefined &&
                resultCache.value.ContainsKey(currentGameId)
              ) {
                cardRequestTimeout = setTimeout(() => {
                  OnrecievedDeskResult();
                }, PULL_CARD_INTERVAL);
                return;
              }

              currentGameResult = new GameResult(
                response,
                (deskData.value as Desk).gameType
              );

              if (
                currentGameResult.state === GameRoundState.END_OF_ROUND &&
                !currentGameResult.isNewShoe
              ) {
                store.commit(AppMutation.ADD_RESULT_TO_CACHE, {
                  key: currentGameId,
                  value: currentGameResult.rawData,
                });

                needRefreshGameResult = true;
                winOrLostAmount.value = currentGameResult.won;

                if (winOrLostAmount.value !== 0) {
                  stopShowWinner();
                  showWinnerInterval = setTimeout(() => {
                    winOrLostAmount.value = 0;
                    showWinnerInterval = false;
                  }, 3000);
                }

                showCardDisplay.value = true;
                setTimeout(() => {
                  emitter.emit(
                    BussEventType.SHOW_GAME_RESULT,
                    currentGameResult
                  );
                }, 10);

                setTimeout(() => {
                  showCardDisplay.value = false;
                }, 6000);

                stopRequestingCard();
                loadData(true);
              } else {
                cardRequestTimeout = setTimeout(() => {
                  OnrecievedDeskResult();
                }, PULL_CARD_INTERVAL);
              }
            }
          })
          .catch((e) => {
            failureCounter += 1;
            if (failureCounter > 3) {
              stopService();
              emitter.emit(BussEventType.TOAST_MESSAGE, e);
              store.commit(AppMutation.CLEAR_ALL);
              router.push({ name: ROUTES.LOGIN });
              return;
            }

            cardRequestTimeout = setTimeout(() => {
              OnrecievedDeskResult();
            }, PULL_CARD_INTERVAL);
          });
      } else {
        stopRequestingCard();
        loadData(true);
      }
    };

    const resetBet = () => {
      if (hasUnconfimerBet.value) emitter.emit(BussEventType.RESET_BET);
    };

    const confirmBet = () => {
      if (hasUnconfimerBet.value) emitter.emit(BussEventType.CONFIRM_BET);
    };

    const handleOnBetSuccess = (_amount: number) => {
      tempTotalBet.value = _amount;
    };

    const viewHistory = () => {
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.open,
        name: "record",
      };
      emitter.emit(BussEventType.DOALOG, _event);
    };

    const viewLimit = () => {
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.open,
        name: "limit",
      };
      emitter.emit(BussEventType.DOALOG, _event);
    };

    const updateOnlineStatus = (e: any) => {
      const { type } = e;
      online.value = type === "online";

      if (online.value) {
        initPlayer();
      }
    };

    const handleToggleOtherMenu = () => {
      showMobileMenu.value = !showMobileMenu.value;
    };

    const handleClickOutsideOtherMenu = () => {
      showMobileMenu.value = false;
    };

    const visitCustumerService = () => {
      if (isMobileDisplay.value) showMobileMenu.value = false;
      window.open(process.env.CUSTOMER_SERVICE_URL, "_blank");
    };

    const goToRule = () => {
      if (isMobileDisplay.value) showMobileMenu.value = false;
      window.open(
        "http://n1.bb3336.com/resource/rules/rule_home.html",
        "_blank"
      );
    };

    const showPwModify = () => {
      if (isMobileDisplay.value) showMobileMenu.value = false;
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.open,
        name: "security",
      };
      emitter.emit(BussEventType.DOALOG, _event);
    };

    const logOut = () => {
      if (isMobileDisplay.value) showMobileMenu.value = false;
      const params = new LogoutParams(user.value);
      store
        .dispatch(AppActions.LOG_OUT, params)
        .then(() => {
          router.push({ name: "select-server-line" });
        })
        .catch((error) => {
          emitter.emit(BussEventType.TOAST_MESSAGE, error);
        });
    };

    const OnSessionExpired = () => {
      stopService();
      stopCountDown();
      disposePlayer();
      store.commit(AppMutation.CLEAR_ALL);
      emitter.emit(BussEventType.TOAST_MESSAGE, "会话已过期");
      router.push({ name: "select-server-line" });
    };

    //#region  Computed Properties
    const token = computed(() => store.getters["token"]);
    const user = computed(() => store.getters["user"]);
    const coin = computed(() => store.getters["coin"]);
    const isFull = computed(() => store.getters["isFull"]);
    const chips = computed((): number[] => store.getters["chips"]);
    const mapData = computed(() => {
      if (deskData.value) return deskData.value.result;
      return "";
    });

    const notice = computed(() => store.getters["notice"]);
    const gameId = computed(() => {
      if (!deskData.value) return "";

      return deskData.value.gameId;
    });

    const resultCache = computed(
      (): Dictionary<string> => store.getters["resultCache"]
    );

    const limitInfo = computed(
      (): LimitInfo | undefined => store.getters["limitInfo"]
    );
    const lang = computed((): string => store.getters["lang"]);

    const isBaccarat = computed((): boolean => {
      return deskData.value?.gameType === 1;
    });

    const currentDesk = computed(
      (): CurrentDesk => store.getters["currentDesk"]
    );

    const redConfirmedBet = computed((): number => {
      if (!deskData.value) return 0;
      return deskData.value.redBet;
    });

    const blueConfirmedBet = computed((): number => {
      if (!deskData.value) return 0;
      return deskData.value.blueBet;
    });

    const greenConfirmedBet = computed((): number => {
      if (!deskData.value) return 0;
      return deskData.value.tieBet;
    });

    const totalBet = computed(() => {
      if (!deskData.value) {
        return 0;
      } else {
        let tempTotalbet = 0;
        const { redBet, redPairBet, bluePairBet, blueBet, tieBet, gameType } =
          deskData.value;

        if (gameType === GAMETYPE.baccarat) {
          tempTotalbet = redBet + redPairBet + blueBet + bluePairBet + tieBet;
        } else {
          tempTotalbet = redBet + blueBet + tieBet;
        }

        if (tempTotalbet < tempTotalBet.value) {
          tempTotalbet = tempTotalBet.value;
        }

        return tempTotalbet;
      }
    });

    const redPairConfirmedBet = computed((): number => {
      if (!deskData.value) return 0;

      return deskData.value.redPairBet;
    });

    const bluePairConfirmedBet = computed((): number => {
      if (!deskData.value) return 0;
      return deskData.value.bluePairBet;
    });
    const gameTypeLabel = computed(() => {
      if (currentDesk.value.gameType === GAME_TYPE.BACCARAT)
        return t("baccarat");
      else if (currentDesk.value.gameType === GAME_TYPE.DRAGON_TIGER)
        return t("dragontiger");
      else
        return deskData.value?.gameType === 1
          ? t("baccarat")
          : t("dragontiger");
    });
    const isMobileDisplay = computed((): boolean => store.getters["isMobile"]);
    const audioIsOn = computed((): boolean => store.getters["audioIsOn"] === 1);
    const videoIsOn = computed((): boolean => store.getters["videoIsOn"] === 1);

    const baccaratArr = computed((): number[] => store.getters["bacTabbles"]);
    const cardFirstArr = computed((): number[] => store.getters["vipTables"]);
    const dragonTigerArr = computed((): number[] => store.getters["dtTables"]);
    const customChip = computed((): number => store.getters["customChip"]);

    const showWinOrLost = computed(() => {
      return winOrLostAmount.value > 0 || winOrLostAmount.value < 0;
    });

    const winOrlostColor = computed((): string => {
      if (winOrLostAmount.value > 0) return "win";
      else if (winOrLostAmount.value < 0) return "lost";
      return "";
    });

    const timerColor = computed((): string => {
      if (currentCount.value > 20) return "green";
      else if (currentCount.value <= 20 && currentCount.value > 10)
        return "orange";
      else if (currentCount.value <= 10 && currentCount.value !== -1)
        return "red";
      return "";
    });

    const displayOrientation = computed(
      (): "portrait" | "landscape" => store.getters["deviceOrientation"]
    );

    const chipAmount = computed((): number => {
      if (activeChip.value <= chips.value.length - 1) {
        return chips.value[activeChip.value];
      }

      if (activeChip.value === 22) return customChip.value;

      return 0;
    });
    //#endregion

    //#region Vue Hooks

    onMounted(() => {
      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
      loadData();
      // initPlayer();
    });

    onBeforeMount(() => {
      const _currentDesk: CurrentDesk = {
        desk: props.desk,
        gameType: props.gameType,
        xian: props.xian,
      };
      setCurrentDesk(_currentDesk);
      emitter.on(BussEventType.WINDOW_RESIZED, handleWindowResized);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);

      emitter.off(BussEventType.WINDOW_RESIZED, handleWindowResized);
      stopService();
      disposePlayer();
      stopCountDown();
      stopRequestingCard();
    });

    //#endregion

    //#region Watcher
    watch(
      () => deskData.value,
      (value) => {
        if (value && needReloadUI) {
          reloadUI();
          if (value.currentTime !== -1) {
            startCountDown();
          } else {
            if (typeof countDownInterval === "number") {
              stopCountDown();
              audioPlayer.Play(SOUNDKEY.STOP_BET);
            }

            currentCount.value = -1;
          }
        } else {
          needReloadUI = true;
        }
      },
      { immediate: true }
    );

    //#endregion

    return {
      deskData,
      currentDesk,
      gameTypeLabel,
      allowBet,
      currentCount,
      user,
      coin,
      isFull,
      t,
      mapData,
      roadMapKey,
      notice,
      baccaratArr,
      cardFirstArr,
      dragonTigerArr,
      videoIsLoading,
      gameId,
      limitInfo,
      isBaccarat,
      betViewKey,
      is360Video,
      audioIsOn,
      videoIsOn,
      togleVideoView,
      formatCash,
      onSelectTable,
      chipClicked,
      isInthisTable,
      handleTempBetUpdated,
      onRoadMapDataParsed,
      predict,
      resetBet,
      confirmBet,
      handleOnBetSuccess,
      viewHistory,
      viewLimit,
      handleToggleOtherMenu,
      handleClickOutsideOtherMenu,
      visitCustumerService,
      goToRule,
      showPwModify,
      logOut,
      OnSessionExpired,
      onShowLanguageMsbBox,
      chips,
      betView,
      roadmapview,
      redConfirmedBet,
      blueConfirmedBet,
      greenConfirmedBet,
      redPairConfirmedBet,
      bluePairConfirmedBet,
      hasUnconfimerBet,
      nextRedRoadMapSummaryData,
      nextBlueRoadMapSummaryData,
      winOrLostAmount,
      totalBet,
      isMobileDisplay,
      showWinOrLost,
      winOrlostColor,
      showMobileMenu,
      timerColor,
      showSwitchTable,
      displayOrientation,
      activeChip,
      chipAmount,
      showCardDisplay,
      toggleFullScreen,
      toggleVideo,
      toggleSound,
    };
  },
});
