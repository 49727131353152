
import BussEventType from "@/types/BussEventType";
import { GameResult } from "@/types/GameResult";
import {
  computed,
  defineComponent,
  Emitter,
  inject,
  onBeforeMount,
  onBeforeUnmount,
  PropType,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    gameType: {
      type: Number as PropType<number>,
      default: () => -1,
    },
  },
  setup() {
    const emitter = inject("emitter") as Emitter;
    const { t } = useI18n();

    const redTotal = ref<number>(0);
    const blueTotal = ref<number>(0);
    const winner = ref<number>(-1);

    const blueHand = ref<string[]>([]);
    const redHand = ref<string[]>([]);

    onBeforeMount(() => {
      // set listener
      emitter.on(BussEventType.SHOW_GAME_RESULT, handleOnResult);
    });

    onBeforeUnmount(() => {
      // remove listener
      emitter.off(BussEventType.SHOW_GAME_RESULT, handleOnResult);
    });

    const handleOnResult = (_gameResult: GameResult) => {
      if (_gameResult) {
        redTotal.value = _gameResult.redTotal;
        blueTotal.value = _gameResult.blueTotal;
        winner.value = _gameResult.whoWin;
        blueHand.value = _gameResult.blueCard;
        redHand.value = _gameResult.redCard;
      }
    };

    const redFirstCard = computed(() => {
      if (redHand.value.length > 0) {
        return `v_${redHand.value[0]}`;
      }
      return "";
    });

    const redSecondCard = computed(() => {
      if (redHand.value.length > 1) {
        return `v_${redHand.value[1]}`;
      }
      return "";
    });

    const redThirdCard = computed(() => {
      if (redHand.value.length > 2 && redHand.value[2] !== "") {
        return `h_${redHand.value[2]}`;
      }
      return "";
    });

    const blueFirstCard = computed(() => {
      if (blueHand.value.length > 0) {
        return `v_${blueHand.value[0]}`;
      }
      return "";
    });

    const blueSecondCard = computed(() => {
      if (blueHand.value.length > 1) {
        return `v_${blueHand.value[1]}`;
      }
      return "";
    });

    const blueThirdCard = computed(() => {
      if (blueHand.value.length > 2 && blueHand.value[2] !== "") {
        return `h_${blueHand.value[2]}`;
      }
      return "";
    });

    return {
      redFirstCard,
      redSecondCard,
      redThirdCard,
      blueFirstCard,
      blueSecondCard,
      blueThirdCard,
      redTotal,
      blueTotal,
      t,
    };
  },
});
